import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import {
    CDataTable,
    CButton,
    CCollapse,
    CCardBody,
    CCol,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CAlert,
    CForm,
    CFormGroup,
    CLabel,
    CInput,
    CFormText,
    CInputCheckbox,
    CValidFeedback,
    CInvalidFeedback,
    CSwitch,
    CRow,
    CTextarea,
    CSelect,
    CInputFile
} from '@coreui/react'
import DataTable from "../../../reusable/DataTable/DataTable"
import Select2 from "../../../reusable/Select2/Select2"
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import '../../../conf/globals.js'

const Actors = () => {

	// const [actors, setActors] = useState();
    // const [all_genders, setGenders] = useState({});
    // const [all_professions, setProfessions] = useState({});

	const [details, setDetails] = useState([])

	const [form, setValue] = useState({
        first_name: '',
        last_name: '',
        birth_year: '',
        file: '',
        biography: '',
        gender: null,
        profession: null,
    });

    const[filename, setFilename] = useState('Choose file...');

    // const [select2Values, setSelect2Values] = useState({
    //     gender: null,
    //     profession: null,
    // })
    const [select2Item, setSelect2Item] = useState({});

    const [errors, setErrors] = useState({
        first_name: {msg: '', isValid: undefined, isInvalid: undefined},
        last_name: {msg: '', isValid: undefined, isInvalid: undefined},
        birth_year: {msg: '', isValid: undefined, isInvalid: undefined},
        file: {msg: '', isValid: undefined, isInvalid: undefined},
        biography: {msg: '', isValid: undefined, isInvalid: undefined},
        gender_id: {msg: '', isValid: undefined, isInvalid: undefined},
        profession_id: {msg: '', isValid: undefined, isInvalid: undefined},
    });

	const [actorModal, setActorModal] = useState(false);
	const [actorId, setActorId] = useState(false);
	const [modalType, setModalType] = useState(false);
    const [message, setMessage] = useState(false);
    const [messageType, setMessageType] = useState(undefined);

    const [birthYear, setBirthYear] = useState(new Date());

     // dispatch
    const dispatch = useDispatch()

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const editActor = async (e) => {

        dumpErrors();

        let response = undefined;

        if (modalType === 'delete') {
            let res = await fetch(global.baseUrl + "api/actors/" + actorId, {method: 'DELETE', headers: { 'Content-Type': 'application/json' }, credentials: 'include'});
            response = await res.json();
        } else if (modalType === 'update') {


            let formData = new FormData();

            formData.append('first_name', form.first_name);
            formData.append('last_name', form.last_name);
            formData.append('birth_year', form.birth_year);
            if (form.file) {
                formData.append('file', form.file);
            }
            formData.append('biography', form.biography);
            formData.append('gender_id', form.gender);
            if (form.profession) {
                formData.append('profession_id', form.profession);
            }

            let res = await fetch(global.baseUrl + "api/actors/" + actorId, {method: 'POST', credentials: 'include', body: formData});
            response = await res.json();


        } else if (modalType === 'create') {

            let formData = new FormData();

            formData.append('first_name', form.first_name);
            formData.append('last_name', form.last_name);
            formData.append('birth_year', form.birth_year);
            if (form.file) {
                formData.append('file', form.file);
            }
            formData.append('biography', form.biography);
            formData.append('gender_id', form.gender);
            if (form.file) {
                formData.append('profession_id', form.profession);
            }

            let res = await fetch(global.baseUrl + "api/actors/add", {method: 'POST', credentials: 'include', body: formData });
            response = await res.json();
        }


        (typeof response !== 'undefined') && setMessage(response.message);
        (typeof response !== 'undefined') && setMessageType(response.type);

        if (response.type !== 'error') {
            toggleModal();
            setDetails([]);
            dispatch({type: 'set', tableReload: Date.now()})
        } else {
            let newErrors = errors;

            Object.keys(newErrors).map((column,index) => {
                newErrors[column].isValid = response.errors[column] ? false : true //response.errors[column][0]
                newErrors[column].isInvalid = response.errors[column] ? true : false //response.errors[column][0]
                newErrors[column].msg = response.errors[column] ? response.errors[column][0] : '' //response.errors[column][0]
            });

            setErrors({...errors, newErrors});
        }
    }

    const dumpMessage = (e) =>{

        !e && setMessage(false);
        !e && setMessageType(undefined);
    }

    const dumpErrors = (e) =>{
        let cleanErrors = errors;

        Object.keys(cleanErrors).map((column,index) => {
            cleanErrors[column] = {msg: '', isValid: undefined, isInvalid: undefined};
        });

        setErrors(cleanErrors);
    }

    const handleInputChange = (event, editor = null) => {

        if (editor && editor.id == 'biography') {
            let value = event;
            // console.log(editor)

            setValue(prev => ({ 
                ...prev,
                biography: value,
            }));

            return;
        }

        const target = event.target;
        const name = target.name;

        let value = target.files ? target.files[0] : target.value;

        setValue(prev => ({ 
            ...prev,
            [name]: value,
        }));

        if (target.files && target.files.length > 0) {
            setFilename(target.files[0].name);
        }
    }

	const toggleDetails = (index) => {
    	const position = details.indexOf(index)
    	let newDetails = details.slice()
    	if (position !== -1) {
      		newDetails.splice(position, 1)
    	} else {
      		newDetails = [...details, index]
    	}
    	setDetails(newDetails)
	}

	const toggleModal = async (e) => {
        setActorModal(!actorModal);
    }


    const prepareAndSetValue = (item, typeOfModal) => {

        let value = {}

        if (typeOfModal == 'update') {

            Object.assign(value, {first_name: item.first_name});
            Object.assign(value, {last_name: item.last_name});
            Object.assign(value, {birth_year: item.birth_year});
            Object.assign(value, {file: ''});
            Object.assign(value, {biography: item.biography});
            Object.assign(value, {gender: item.gender_id});
            Object.assign(value, {profession: item.profession_id});

        	setBirthYear(new Date(item.birth_year, '01', '01'));

            setSelect2Item({
                gender: {label: item.gender, value: item.gender_id},
                profession: item.profession_id ? {label: item.profession, value: item.profession_id} : null
            })

            setFilename(item.image);

        } else if (typeOfModal == 'create') {

            Object.assign(value, {first_name: ''});
            Object.assign(value, {last_name: ''});
            Object.assign(value, {birth_year: ''});
            Object.assign(value, {biography: ''});
            Object.assign(value, {gender: null});
            Object.assign(value, {profession: null});

            setBirthYear(new Date());

            setSelect2Item({
                gender: null,
                profession: null
            })
            setFilename('Choose file...');
        }


        setValue(value);
    }


	const fields = [
        { key: 'id', _style: { width: '4%'}, label: 'ID' },
    	{ key: 'first_name', _style: { width: '40%'} },
        { key: 'last_name', _style: { width: '40%'} },
    	{ key: 'birth_year', _style: { width: '40%'} },
    	{
	      	key: 'show_details',
	      	label: '',
	      	_style: { width: '1%' },
	      	sorter: false,
	      	filter: false
    	}
	]

    const options = {
        credentials: 'include'
    }

    const alertColors = {
        success: 'success',
        error: 'danger'
    }

	return (
		<div>
			<div className="row">
                <div className="col-md-3 justify-content-center align-self-center">
                    <CButton className="my-2" color="success" onClick={(e) => {toggleModal(); setModalType('create'); prepareAndSetValue(null, 'create'); dumpErrors();}}>Add new actor</CButton>
                </div>
                <div className="col-md-9 justify-content-center align-self-center">
                    <CAlert className="m-0" show={!!message} color={alertColors[messageType]} onShowChange={dumpMessage} closeButton>
                        {message}
                    </CAlert>
                </div>
            </div>
			<DataTable
    		loading
      		fields={fields}
      		columnFilter
      		tableFilter
      		footer
      		itemsPerPageSelect
            itemsPerPage={10}
      		hover
      		sorter
            exportData
      		pagination
            server_side
            url={global.baseUrl + "api/actors"}
            options={options}
      		scopedSlots = {{
                'birth_year':
                (item,index)=>{
                    return (
                        <td>
                            {item.birth_year ? item.birth_year : ''}
                        </td>
                    )
                },
        		'show_details':
          		(item, index)=>{
            		return (
              			<td className="py-2">
                			<CButton
              				color="primary"
                  			variant="outline"
                  			shape="square"
                  			size="sm"
                  			onClick={()=>{toggleDetails(index)}}
                			>
                  				{details.includes(index) ? 'Hide' : 'Show'}
                			</CButton>
              			</td>
              		)
          		},
        		'details':
            	(item, index)=>{
              		return (
              			<CCollapse show={details.includes(index)}>
                			<CCardBody>
                				<CRow>
                					<CCol>
                                        <CCol className="col-12 py-3">
                                           { item.image && <img className="w-100" src={global.baseUrl + 'actor_images/' + item.image}/>}
                                        </CCol>
	                					<CCol className="col-12 py-3">
	            							<h4>{item.first_name + ' ' + item.last_name} <FontAwesomeIcon icon={item.gender_icon} /></h4>
	                					</CCol>
	                					<CCol className="col-12">
	            							<h5>Birth year: {item.birth_year}</h5>
	                					</CCol>
                                        <CCol className="col-12">
                                            <h5>Profession: {item.profession || '/'}</h5>
                                        </CCol>
                					</CCol>
            						<CCol lg="8" className="border py-3 my-2">
            							<h5>Biography:</h5>
            							<p style={{whiteSpace: "break-spaces"}} dangerouslySetInnerHTML={{ __html: item.biography}}></p>
                					</CCol>
                				</CRow>
                  				<CButton size="sm" color="info" onClick={(e) => {toggleModal(); setModalType('update'); setActorId(item.id);prepareAndSetValue(item, 'update'); dumpErrors();}}>
                    				Actor Settings
                  				</CButton>
                  				<CButton size="sm" color="danger" className="ml-1" onClick={(e) => {toggleModal(); setModalType('delete');  setActorId(item.id);}}>
                    				Delete
                  				</CButton>
                			</CCardBody>
              			</CCollapse>
            		)
          		}
      		}}
    		/>
    		<CModal
                show={actorModal}
                onClose={toggleModal}
            >
                <CModalHeader closeButton>
                    {modalType === 'delete' ? 'Delete actor' : modalType === 'update' ? 'Update actor' : 'Add new actor'}
                </CModalHeader>
                <CModalBody>
                    {modalType === 'delete' ? 'Are you sure that you want to remove actor?' : 
                    <CForm action="" method="post">
                        <CFormGroup>
                            <CLabel htmlFor="first-name">First name</CLabel>
                            <CInput
                            type="text"
                            id="first-name"
                            name="first_name"
                            placeholder={"Enter First name.."}
                            autoComplete="text"
                            value={form.first_name}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.first_name.isValid}
                            invalid={errors.first_name.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.first_name.msg}</CInvalidFeedback>
                            <CFormText className="help-block">First name</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="last-name">Last name</CLabel>
                            <CInput
                            type="text"
                            id="last-name"
                            name="last_name"
                            placeholder={"Enter Last name.."}
                            autoComplete="text"
                            value={form.last_name}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.last_name.isValid}
                            invalid={errors.last_name.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.last_name.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Last name</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="birth-year">Birth year</CLabel>
                            <div className="knv-react-datepicker-wrapper">
	                            <DatePicker
	                            id="birth-year"
	                        	name="birth_year"
				      			selected={birthYear}
						      	onChange={(date) => {setBirthYear(date);setValue(prev => ({...prev, birth_year: new Date(date).getFullYear()}));}}
						      	showYearPicker
						      	dateFormat="yyyy"
						      	className="form-control w-100"
							    />
                            </div>
                        	<div>
                        	{
                        		typeof errors.birth_year === 'undefined' ?

                            	<small className="text-success">Input is valid!</small>
                        		:
                            	<small className="text-danger">{errors.birth_year.msg}</small>

                        	}
                        	</div>
                            <CFormText className="help-block">Birth year</CFormText>
                        </CFormGroup>
                        <CFormGroup row className="mx-0">
                            <CLabel col md={3} className="pl-0">Image</CLabel>
                            <CCol xs="12" md="9">
                                <CInputFile 
                                id="file" 
                                name="file"
                                filename="image"
                                // value={form.file}
                                onChange={(event) => handleInputChange(event)}
                                custom
                                />
                                <CLabel htmlFor="file" variant="custom-file">
                                    {filename}
                                </CLabel>
                                {
                                    typeof errors.file === 'undefined' ?

                                    <small className="text-success">Input is valid!</small>
                                    :
                                    <small className="text-danger">{errors.file.msg}</small>

                                }
                            </CCol>
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.file.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Upload</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="biography">Biography</CLabel>


                            <Editor
                            id="biography"
                            name="biography"
                            value={form.biography}
                            onEditorChange={(content, editor) => handleInputChange(content, editor)}
                            init={{
                               content_css: false,
                               height: 500,
                               menubar: false,
                               plugins: [
                                 'link image',
                                 'table paste',
                                 'code',
                                 'link'
                               ],
                               toolbar:
                                 'undo redo | formatselect | bold italic backcolor | \
                                 alignleft aligncenter alignright alignjustify | \
                                 bullist numlist outdent indent | removeformat | help | \
                                 link | code'
                             }}
                            />
                            

                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.biography.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Biography</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="gender">Gender</CLabel>
                            <Select2
                            url={global.baseUrl + "api/genders"}
                            options={{credentials: 'include'}}
                            customKeys={{key:'id', label:'gender'}}
                            dataKey="data"
                            className="form-control"
                            value={ select2Item && (select2Item.gender && select2Item.gender) }
                            onChange={(value) => {setValue(prev => ({ ...prev, gender: value, }));}}
                            setter={setSelect2Item}
                            isClearable={false}
                            keyToUpdate="gender"
                            />
                            {
                                typeof errors.gender_id === 'undefined' ?

                                <small className="text-success">Input is valid!</small>
                                :
                                <small className="text-danger">{errors.gender_id.msg}</small>

                            }
                            <CFormText className="help-block">Gender</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="profession">Profession</CLabel>
                            <Select2
                            url={global.baseUrl + "api/professions"}
                            options={{credentials: 'include'}}
                            customKeys={{key:'id', label:'name'}}
                            dataKey="data"
                            className="form-control"
                            value={ select2Item && (select2Item.profession && select2Item.profession) }
                            onChange={(value) => {setValue(prev => ({ ...prev, profession: value }));}}
                            setter={setSelect2Item}
                            isClearable
                            keyToUpdate="profession"
                            />
                            <CFormText className="help-block">Profession</CFormText>
                        </CFormGroup>
                    </CForm>

                    }
                </CModalBody>
                <CModalFooter>
                    <CButton color="primary" onClick={editActor}>Confirm</CButton>{' '}
                    <CButton
                        color="secondary"
                        onClick={toggleModal}
                    >Cancel</CButton>
                </CModalFooter>
            </CModal>
		</div>
	)
}

export default Actors