import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import {
    CDataTable,
    CButton,
    CCollapse,
    CCardBody,
    CCol,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CAlert,
    CForm,
    CFormGroup,
    CLabel,
    CInput,
    CFormText,
    CInputCheckbox,
    CValidFeedback,
    CInvalidFeedback,
    CSwitch,
    CRow,
    CTextarea
} from '@coreui/react'
import DataTable from "../../reusable/DataTable/DataTable"
import Select2 from "../../reusable/Select2/Select2"
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import '../../conf/globals.js'

const Changes = () => {
    
	const [details, setDetails] = useState([])

	const [form, setValue] = useState({
        from: null,
        to: null,
        overpass: false,
        opportunited_overpass: false,
        political_function_changed: false,
        description: '',
        actor_id: '',
        political_party_id: '',
        coalition_id: '',
        assembly_id: '',
        political_function_id: '',
        power_status_id: '',
    });

    const [select2Item, setSelect2Item] = useState({});

    const [errors, setErrors] = useState({
        from: {msg: '', isValid: undefined, isInvalid: undefined},
        to: {msg: '', isValid: undefined, isInvalid: undefined},
        overpass: {msg: '', isValid: undefined, isInvalid: undefined},
        opportunited_overpass: {msg: '', isValid: undefined, isInvalid: undefined},
        political_function_changed: {msg: '', isValid: undefined, isInvalid: undefined},
        description: {msg: '', isValid: undefined, isInvalid: undefined},
        actor_id: {msg: '', isValid: undefined, isInvalid: undefined},
        political_party_id: {msg: '', isValid: undefined, isInvalid: undefined},
        coalition_id: {msg: '', isValid: undefined, isInvalid: undefined},
        assembly_id: {msg: '', isValid: undefined, isInvalid: undefined},
        political_function_id: {msg: '', isValid: undefined, isInvalid: undefined},
        power_status_id: {msg: '', isValid: undefined, isInvalid: undefined},
    });

	const [changeModal, setChangeModal] = useState(false);
	const [changeId, setChangeId] = useState(false);
	const [modalType, setModalType] = useState(false);
    const [message, setMessage] = useState(false);
    const [messageType, setMessageType] = useState(undefined);

    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);

     // dispatch
    const dispatch = useDispatch()

    const editChange = async (e) => {

        dumpErrors();

        let response = undefined;

        if (modalType === 'delete') {
            let res = await fetch(global.baseUrl + "api/changes/" + changeId, {method: 'DELETE', headers: { 'Content-Type': 'application/json' }, credentials: 'include'});
            response = await res.json();
        } else if (modalType === 'update') {

            let res = await fetch(global.baseUrl + "api/changes/" + changeId, {method: 'PUT', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"from": form.from, "to": form.to, "overpass": form.overpass, "opportunited_overpass": form.opportunited_overpass, "political_function_changed": form.political_function_changed, "description": form.description, "actor_id": form.actor_id, "political_party_id": form.political_party_id, "coalition_id": form.coalition_id, "assembly_id": form.assembly_id, "political_function_id": form.political_function_id, "power_status_id": form.power_status_id})});
            response = await res.json();


        } else if (modalType === 'create') {

            let res = await fetch(global.baseUrl + "api/changes/add", {method: 'POST', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"from": form.from, "to": form.to, "overpass": form.overpass, "opportunited_overpass": form.opportunited_overpass, "political_function_changed": form.political_function_changed, "description": form.description, "actor_id": form.actor_id, "political_party_id": form.political_party_id, "coalition_id": form.coalition_id, "assembly_id": form.assembly_id, "political_function_id": form.political_function_id, "power_status_id": form.power_status_id})});
            response = await res.json();
        }


        (typeof response !== 'undefined') && setMessage(response.message);
        (typeof response !== 'undefined') && setMessageType(response.type);

        if (response.type !== 'error') {
            toggleModal();
            setDetails([]);
            dispatch({type: 'set', tableReload: Date.now()})
        } else {
            let newErrors = errors;

            Object.keys(newErrors).map((column,index) => {
                newErrors[column].isValid = response.errors[column] ? false : true //response.errors[column][0]
                newErrors[column].isInvalid = response.errors[column] ? true : false //response.errors[column][0]
                newErrors[column].msg = response.errors[column] ? response.errors[column][0] : '' //response.errors[column][0]
            });

            setErrors({...errors, newErrors});
        }
    }

    const dumpMessage = (e) =>{

        !e && setMessage(false);
        !e && setMessageType(undefined);
    }

    const dumpErrors = (e) =>{
        let cleanErrors = errors;

        Object.keys(cleanErrors).map((column,index) => {
            cleanErrors[column] = {msg: '', isValid: undefined, isInvalid: undefined};
        });

        setErrors(cleanErrors);
    }

    const handleInputChange = (event) => {


        const target = event.target;
        const name = target.name;

        let value = target.value;

        if (target.type === 'checkbox') {

            if (target.checked) {

                value = true;

            } else {
                value = false;
            }

        } else {
            value = target.value;
        }

        setValue(prev => ({ 
            ...prev,
            [name]: value,
        }));
    }

	const toggleDetails = (index) => {
    	const position = details.indexOf(index)
    	let newDetails = details.slice()
    	if (position !== -1) {
      		newDetails.splice(position, 1)
    	} else {
      		newDetails = [...details, index]
    	}
    	setDetails(newDetails)
	}

	const toggleModal = async (e) => {
        setChangeModal(!changeModal);
    }


    const prepareAndSetValue = (item, typeOfModal) => {

        let value = {}

        if (typeOfModal == 'update') {


            Object.assign(value, {from: item.from});
            Object.assign(value, {to: item.to});
            Object.assign(value, {overpass: item.overpass});
            Object.assign(value, {opportunited_overpass: item.opportunited_overpass});
            Object.assign(value, {political_function_changed: item.political_function_changed});
            Object.assign(value, {description: item.description ?? ''});
            Object.assign(value, {actor_id: item.actor_id});
            Object.assign(value, {political_party_id: item.political_party_id});
            Object.assign(value, {coalition_id: item.coalition_id});
            Object.assign(value, {assembly_id: item.assembly_id});
            Object.assign(value, {political_function_id: item.political_function_id});
            Object.assign(value, {power_status_id: item.power_status_id});

            setFrom(new Date(item.from));
        	setTo(item.to ? new Date(item.to) : null);

        	setSelect2Item({
                actor: {label: item.actor.name, value: item.actor_id},
                party: {label: item.political_party.name, value: item.political_party_id},
                coalition: {label: item.coalition.name, value: item.coalition_id},
                assembly: {label: item.territory.name + ' ' + item.from, value: item.assembly_id},
                political_function: {label: item.political_function.name, value: item.political_function_id},
                power_status: {label: item.power_status.status, value: item.power_status_id},
                // territory: {label: item.territory.name, value: item.territory_id} power_status
            })

        } else if (typeOfModal == 'create') {

            Object.assign(value, {from: null});
            Object.assign(value, {to: null});
            Object.assign(value, {overpass: false});
            Object.assign(value, {opportunited_overpass: false});
            Object.assign(value, {political_function_changed: false});
            Object.assign(value, {description: ''});
            Object.assign(value, {actor_id: ''});
            Object.assign(value, {political_party_id: ''});
            Object.assign(value, {coalition_id: ''});
            Object.assign(value, {assembly_id: ''});
            Object.assign(value, {political_function_id: ''});
            Object.assign(value, {power_status_id: ''});

            setFrom(null);
        	setTo(null);

        	setSelect2Item({
                actor: null,
                party: null,
                coalition: null,
                assembly: null,
                political_function: null,
                power_status: null,
                // territory: null
            })
        }


        setValue(value);
    }


	const fields = [
        { key: 'actor_id', _style: { width: '1%'}, label: 'Actor ID' },
        { key: 'actor.first_name', _style: { width: '20%'}, label: 'First name' },
        { key: 'actor.last_name', _style: { width: '20%'}, label: 'Last name' },
    	{ key: 'actor.birth_year', _style: { width: '20%'}, label: 'Birth year' },
    	{ key: 'assembly.territory.name', _style: { width: '20%'}, label: 'Territory' },
    	{ key: 'from', _style: { width: '20%'} },
    	{ key: 'to', _style: { width: '20%'} },
        { key: 'political_party.name', _style: { width: '20%'}, label: 'Party' },
    	{ key: 'coalition.name', _style: { width: '20%'}, label: 'Coalition' },
        { key: 'political_function.name', _style: { width: '20%'}, label: 'Function' },
    	{ key: 'power_status.status', _style: { width: '20%'}, label: 'Power status' },
    	{ key: 'overpass', _style: { width: '20%'}, filter: false },
    	{
	      	key: 'show_details',
	      	label: '',
	      	_style: { width: '1%' },
	      	sorter: false,
	      	filter: false
    	}
	]

    const options = {
        credentials: 'include'
    }

    const alertColors = {
        success: 'success',
        error: 'danger'
    }

	return (
		<div>
			<div className="row">
                <div className="col-md-3 justify-content-center align-self-center">
                    <CButton className="my-2" color="success" onClick={(e) => {toggleModal(); setModalType('create'); prepareAndSetValue(null, 'create'); dumpErrors();}}>Add new change</CButton>
                </div>
                <div className="col-md-9 justify-content-center align-self-center">
                    <CAlert className="m-0" show={!!message} color={alertColors[messageType]} onShowChange={dumpMessage} closeButton>
                        {message}
                    </CAlert>
                </div>
            </div>
			<DataTable
    		loading
      		fields={fields}
      		columnFilter
      		footer
      		itemsPerPageSelect = {{ label: 'Items per page:',  values: [5, 10, 20, 50, 200, 500, 1000, 5000, 10000, 1000000] }}
            itemsPerPage={10}
      		hover
      		sorter
            exportData
      		pagination
            server_side
            url={global.baseUrl + "api/changes"}
            options={options}
      		scopedSlots = {{
      			'actor.first_name':
          		(item, index)=>{
            		return (
              			<td>
                			{item.actor.first_name}
              			</td>
              		)
          		},
          		'actor.last_name':
          		(item, index)=>{
            		return (
              			<td>
                			{item.actor.last_name}
              			</td>
              		)
          		},
                'actor.birth_year':
                (item, index)=>{
                    return (
                        <td>
                            {item.actor.birth_year}
                        </td>
                    )
                },
                'assembly.territory.name':
                (item, index)=>{
                    return (
                        <td>
                            {item.territory.name}
                        </td>
                    )
                },
          		'from':
          		(item, index)=>{
            		return (
              			<td>
                			{moment(item.from).format('DD.MM.YYYY.')}
              			</td>
              		)
          		},
          		'to':
          		(item, index)=>{
            		return (
              			<td>
                			{item.to ? moment(item.to).format('DD.MM.YYYY.') : ''}
              			</td>
              		)
          		},
          		'political_party.name':
          		(item, index)=>{
            		return (
              			<td>
                			{item.political_party.name}
              			</td>
              		)
          		},
                'coalition.name':
                (item, index)=>{
                    return (
                        <td>
                            {item.coalition.name}
                        </td>
                    )
                },
          		'political_function.name':
          		(item, index)=>{
            		return (
              			<td>
                			{item.political_function.name}
              			</td>
              		)
          		},
                'power_status.status':
                (item, index)=>{
                    return (
                        <td>
                            {item.power_status.status}
                        </td>
                    )
                },
          		'overpass':
          		(item, index)=>{
            		return (
              			<td className="text-center">
                			{item.overpass ? <FontAwesomeIcon icon="dove" className="c-sidebar-nav-icon"/> : ''}
              			</td>
              		)
          		},
        		'show_details':
          		(item, index)=>{
            		return (
              			<td className="py-2">
                			<CButton
              				color="primary"
                  			variant="outline"
                  			shape="square"
                  			size="sm"
                  			onClick={()=>{toggleDetails(index)}}
                			>
                  				{details.includes(index) ? 'Hide' : 'Show'}
                			</CButton>

                            <CButton
                            className={"mt-1 w-100"}
                            color="info"
                            variant="outline"
                            shape='square'
                            size="sm"
                            onClick={(e) => {toggleModal(); setModalType('update'); setChangeId(item.id);prepareAndSetValue(item, 'update'); dumpErrors();}}
                            >
                                Edit
                            </CButton>
              			</td>
              		)
          		},
        		'details':
            	(item, index)=>{
              		return (
              			<CCollapse show={details.includes(index)}>
                			<CCardBody>
                                <CRow>
                                    <CCol className="mb-3">
                                        <small className="text-muted"><i>Last update by {item.user.name}</i></small>
                                    </CCol>
                                </CRow>
                  				<CButton size="sm" color="danger" className="ml-1" onClick={(e) => {toggleModal(); setModalType('delete');  setChangeId(item.id);}}>
                    				Delete
                  				</CButton>
                			</CCardBody>
              			</CCollapse>
            		)
          		}
      		}}
    		/>
    		<CModal
                size="lg"
                show={changeModal}
                onClose={toggleModal}
            >
                <CModalHeader closeButton>
                    {modalType === 'delete' ? 'Delete change' : modalType === 'update' ? 'Update change' : 'Add new change'}
                </CModalHeader>
                <CModalBody>
                    {modalType === 'delete' ? 'Are you sure that you want to remove change?' : 
                    <CForm className="row" action="" method="post">
                        <CCol sm="6">
                        	<CFormGroup>
                                <CLabel htmlFor="from">From</CLabel>
                                <div className="knv-react-datepicker-wrapper">
    	                            <DatePicker
    	                            id="from"
    	                        	name="from"
    				      			selected={from}
    						      	onChange={(date) => {setFrom(date);setValue(prev => ({...prev, from: moment(date).format('YYYY-MM-DD')}));}}
    						      	// showYearPicker
    						      	dateFormat="dd.MM.yyyy."
    						      	className="form-control w-100"
    							    />
                                </div>
                            	<div>
                            	{
                            		typeof errors.from === 'undefined' ?

                                	<small className="text-success">Input is valid!</small>
                            		:
                                	<small className="text-danger">{errors.from.msg}</small>

                            	}
                            	</div>
                                <CFormText className="help-block">From</CFormText>
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel htmlFor="to">To</CLabel>
                                <div className="knv-react-datepicker-wrapper">
    	                            <DatePicker
    	                            id="to"
    	                        	name="to"
    				      			selected={to}
    						      	onChange={(date) => {setTo(date);setValue(prev => ({...prev, to: date ? moment(date).format('YYYY-MM-DD') : null}));}}
    						      	// showYearPicker
    						      	dateFormat="dd.MM.yyyy."
    						      	className="form-control w-100"
    							    />
                                </div>
                            	<div>
                            	{
                            		typeof errors.to === 'undefined' ?

                                	<small className="text-success">Input is valid!</small>
                            		:
                                	<small className="text-danger">{errors.to.msg}</small>

                            	}
                            	</div>
                                <CFormText className="help-block">To</CFormText>
                            </CFormGroup>
                            <CFormGroup>
    	                        <CSwitch
    	                      	id="overpass"
    	                      	name='overpass'
    	                      	className="mr-2"
    	                      	color="success"
    	                      	checked={form.overpass}
    	                      	size='sm'
    	                      	variant='3d'
    	                      	value={true}
    	                      	onChange={(event) => handleInputChange(event)}
    	                    	/>
    	                   		<CLabel className='align-top' style={{cursor: 'pointer'}} variant="checkbox" htmlFor="overpass">Overpass</CLabel>
    	                   	</CFormGroup>
    	                   	<CFormGroup>
    	                        <CSwitch
    	                      	id="opportunited-overpass"
    	                      	name='opportunited_overpass'
    	                      	className="mr-2"
    	                      	color="success"
    	                      	checked={form.opportunited_overpass}
    	                      	size='sm'
    	                      	variant='3d'
    	                      	value={true}
    	                      	onChange={(event) => handleInputChange(event)}
    	                    	/>
    	                   		<CLabel className='align-top' style={{cursor: 'pointer'}} variant="checkbox" htmlFor="opportunited-overpass">Opportunited Overpass</CLabel>
    	                   	</CFormGroup>
    	                   	<CFormGroup>
    	                        <CSwitch
    	                      	id="political-function-changed"
    	                      	name='political_function_changed'
    	                      	className="mr-2"
    	                      	color="success"
    	                      	checked={form.political_function_changed}
    	                      	size='sm'
    	                      	variant='3d'
    	                      	value={true}
    	                      	onChange={(event) => handleInputChange(event)}
    	                    	/>
    	                   		<CLabel className='align-top' style={{cursor: 'pointer'}} variant="checkbox" htmlFor="political-function-changed">Political function changed</CLabel>
    	                   	</CFormGroup>
    	                   	<CFormGroup>
                                <CLabel htmlFor="biography">Description</CLabel>
                                <CTextarea
                                id="description"
                                name="description"
                                placeholder={"Description.."}
                                rows="9"
                                value={form.description}
                                onChange={(event) => handleInputChange(event)}
                                valid={errors.description.isValid}
                                invalid={errors.description.isInvalid}
                                />
                                <CValidFeedback>Input is valid!</CValidFeedback>
                                <CInvalidFeedback>{errors.description.msg}</CInvalidFeedback>
                                <CFormText className="help-block">Description</CFormText>
                            </CFormGroup>
                        </CCol>
                        <CCol  sm="6">
                            <CFormGroup>
                                <CLabel htmlFor="gender">Actor</CLabel>
                                <Select2
                                url={global.baseUrl + "api/actors"}
                                options={{credentials: 'include'}}
                                customKeys={{key:'id', label:'name_id'}}
                                dataKey="data"
                                className="form-control"
                                value={ select2Item && (select2Item.actor && select2Item.actor) }
                                onChange={(value) => {setValue(prev => ({ ...prev, actor_id: value, }));}}
                                setter={setSelect2Item}
                                isClearable={false}
                                keyToUpdate="actor"
                                />
                                {
                                    typeof errors.actor_id === 'undefined' ?

                                    <small className="text-success">Input is valid!</small>
                                    :
                                    <small className="text-danger">{errors.actor_id.msg}</small>

                                }
                                <CFormText className="help-block">Actor</CFormText>
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel htmlFor="party">Party</CLabel>
                                <Select2
                                id="party"
                                url={global.baseUrl + "api/political_parties"}
                                options={{credentials: 'include'}}
                                customKeys={{key:'id', label:'name'}}
                                dataKey="data"
                                className="form-control"
                                value={ select2Item && (select2Item.party && select2Item.party) }
                                onChange={(value) => {setValue(prev => ({ ...prev, political_party_id: value, }));}}
                                setter={setSelect2Item}
                                isClearable={false}
                                keyToUpdate="party"
                                />
                                {
                                    typeof errors.political_party_id === 'undefined' ?

                                    <small className="text-success">Input is valid!</small>
                                    :
                                    <small className="text-danger">{errors.political_party_id.msg}</small>

                                }
                                <CFormText className="help-block">Party</CFormText>
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel htmlFor="coalition">Coalition</CLabel>
                                <Select2
                                id="coalition"
                                url={global.baseUrl + "api/coalitions"}
                                options={{credentials: 'include'}}
                                customKeys={{key:'id', label:'name'}}
                                dataKey="data"
                                className="form-control"
                                value={ select2Item && (select2Item.coalition && select2Item.coalition) }
                                onChange={(value) => {setValue(prev => ({ ...prev, coalition_id: value, }));}}
                                setter={setSelect2Item}
                                isClearable={false}
                                keyToUpdate="coalition"
                                />
                                {
                                    typeof errors.coalition_id === 'undefined' ?

                                    <small className="text-success">Input is valid!</small>
                                    :
                                    <small className="text-danger">{errors.coalition_id.msg}</small>

                                }
                                <CFormText className="help-block">Coalition</CFormText>
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel htmlFor="assembly">Assembly</CLabel>
                                <Select2
                                id="assembly"
                                url={global.baseUrl + "api/assemblies"}
                                options={{credentials: 'include'}}
                                customKeys={{key:'id', label:'name'}}
                                dataKey="data"
                                className="form-control"
                                value={ select2Item && (select2Item.assembly && select2Item.assembly) }
                                onChange={(value) => {setValue(prev => ({ ...prev, assembly_id: value, }));}}
                                setter={setSelect2Item}
                                isClearable={false}
                                keyToUpdate="assembly"
                                colSearch="territory.name"
                                />
                                {
                                    typeof errors.assembly_id === 'undefined' ?

                                    <small className="text-success">Input is valid!</small>
                                    :
                                    <small className="text-danger">{errors.assembly_id.msg}</small>

                                }
                                <CFormText className="help-block">Assembly</CFormText>
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel htmlFor="political-function">Political function</CLabel>
                                <Select2
                                id="political-function"
                                url={global.baseUrl + "api/political_functions"}
                                options={{credentials: 'include'}}
                                customKeys={{key:'id', label:'name'}}
                                dataKey="data"
                                className="form-control"
                                value={ select2Item && (select2Item.political_function && select2Item.political_function) }
                                onChange={(value) => {setValue(prev => ({ ...prev, political_function_id: value, }));}}
                                setter={setSelect2Item}
                                isClearable={false}
                                keyToUpdate="political_function"
                                />
                                {
                                    typeof errors.political_function_id === 'undefined' ?

                                    <small className="text-success">Input is valid!</small>
                                    :
                                    <small className="text-danger">{errors.political_function_id.msg}</small>

                                }
                                <CFormText className="help-block">Political function</CFormText>
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel htmlFor="power-status">Power status</CLabel>
                                <Select2
                                id="power-status"
                                url={global.baseUrl + "api/power_statuses"}
                                options={{credentials: 'include'}}
                                customKeys={{key:'id', label:'status'}}
                                dataKey="data"
                                className="form-control"
                                value={ select2Item && (select2Item.power_status && select2Item.power_status) }
                                onChange={(value) => {setValue(prev => ({ ...prev, power_status_id: value, }));}}
                                setter={setSelect2Item}
                                isClearable={false}
                                keyToUpdate="power_status"
                                />
                                {
                                    typeof errors.power_status_id === 'undefined' ?

                                    <small className="text-success">Input is valid!</small>
                                    :
                                    <small className="text-danger">{errors.power_status_id.msg}</small>

                                }
                                <CFormText className="help-block">Power status</CFormText>
                            </CFormGroup>
                        </CCol>
                    </CForm>

                    }
                </CModalBody>
                <CModalFooter>
                    <CButton color="primary" onClick={editChange}>Confirm</CButton>{' '}
                    <CButton
                        color="secondary"
                        onClick={toggleModal}
                    >Cancel</CButton>
                </CModalFooter>
            </CModal>
		</div>
	)
}

export default Changes